import { createSlice } from '@reduxjs/toolkit'
import { EMPTY_DATA_SEARCH } from './constant/codeCitad';


const initialState = {
    search: {
        ...EMPTY_DATA_SEARCH
    }
};

export const codeCitadSlice = createSlice({
    name: 'codeCitad',
    initialState,
    reducers: {
        searchCodeCitad: (state, action) => {
            (state.search = action.payload);
        },
        searchCodeCitadPageFirst: (state, action) => {
            state.search.page = 0;
        },
    },
})


const { reducer, actions } = codeCitadSlice;
// Action creators are generated for each case reducer function
export const { searchCodeCitad, searchCodeCitadPageFirst } = actions

export const selectSearchCodeCitad = (state) => state?.features?.codeCitad?.search

export default reducer


