import { createSlice } from '@reduxjs/toolkit'
import { EMPTY_DATA_SEARCH } from './constant/service';

const initialState = {
    search:{
        ...EMPTY_DATA_SEARCH
    }
};

export const serviceSlice = createSlice({
    name: 'service',
    initialState,
    reducers: {
        searchService: (state, action) => {
            return state.search = action.payload;
        },
        searchServicePageFirst: (state, action) => {
            return state.search.page = 0;
        },
    },
})

// export const authSlice = createSlice({
//     name: 'auth',
//     initialState,
//     reducers: {
//         setAuthData: (state, action) => {
//             return state = { ...state, userData: action.payload, isAuth: true };
//         },
//         setAvatarData: (state, action) => {
//             state.avatar = action.payload;
//         },
//         unsetAuthData: (state) => {
//             return state = { ...initialState };
//         },
//         authData401: (state, action) => {
//             return state = { ...state, isAuth: false, dataError401: action.payload };
//         },
//     },
// })


const { reducer, actions } = serviceSlice;
// Action creators are generated for each case reducer function
export const { searchService, searchServicePageFirst } = actions

export const selectSearchService = (state) => state?.features?.service?.search

export default reducer


