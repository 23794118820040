import { combineReducers } from "redux"
import  configSlice  from "views/category/config/configSlice";
// import userSlice from "features/users/userSlice";
import  serviceSlice  from "views/category/service/serviceSlice";
import  codeCitadSlice  from "views/category/category/code-citad/codeCitadSlice";
import  codeNapasSlice  from "views/category/category/code-napas/codeNapasSlice";
import  accountSlice  from "views/category/system/account/accountSlice";


// import approvalGroupSlice from "features/category-approval-group/approvalGroupSlice";
// import mailingHistorySlice from "features/mailing-history/mailingHistorySlice";
// import mailSlice from "features/category-mail/mailSlice";
// import authoritySlice from "features/authority-manager/authoritySlice";
// import contractTypeSlice from "features/category-contract-type/contractTypeSlice";
// import documentSlice from "features/category-document/documentSlice";
// import { documentTypeSlice } from "features/category-document-type/contractTypeSlice";

const featuresReducer = combineReducers({
    service: serviceSlice,
    config: configSlice,
    codeCitad: codeCitadSlice,
    codeNapas: codeNapasSlice,
    account: accountSlice


})

export default featuresReducer
