import { createSlice } from '@reduxjs/toolkit'
import { EMPTY_DATA_SEARCH } from './constant/account';


const initialState = {
    search: {
        ...EMPTY_DATA_SEARCH
    }
};

export const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        searchAccount: (state, action) => {
            state.search = action.payload;
        },
        searchAccountPageFirst: (state, action) => {
            state.search.page = 0;
        },
    },
})


const { reducer, actions } = accountSlice;
// Action creators are generated for each case reducer function
export const { searchAccount, searchAccountPageFirst } = actions

export const selectSearchAccount = (state) => state?.features?.account?.search

export default reducer


