import moment from "moment";

export const RANGER_DATE_CALENDAR ="1900:2100";

export const PAGES = [
    5, 10, 20, 50, 100
];

export const ROW_DEFAULT=5;
export const PAGE_DEFAULT=0;

export const MAX_FILE_SIGN = 50;

export const OTP_TIME = 300 * 1000;

export const MAX_SIZE_FILE=2;

export const VI_LANG='vi';
export const EN_LANG='en';

export const TIME_SIGN_TOKEN = moment(new Date()).format("DD-MM-YYYY hh:mm:ss")
