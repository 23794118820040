import { createSlice } from '@reduxjs/toolkit'
import { EMPTY_DATA_SEARCH } from './constant/codeNapas';


const initialState = {
    search: {
        ...EMPTY_DATA_SEARCH
    }
};

export const codeNapasSlice = createSlice({
    name: 'codeNapas',
    initialState,
    reducers: {
        searchCodeNapas: (state, action) => {
            (state.search = action.payload);
        },
        searchCodeNapasPageFirst: (state, action) => {
            state.search.page = 0;
        },
    },
})


const { reducer, actions } = codeNapasSlice;
// Action creators are generated for each case reducer function
export const { searchCodeNapas, searchCodeNapasPageFirst } = actions

export const selectSearchCodeNapas = (state) => state?.features?.codeNapas?.search

export default reducer


