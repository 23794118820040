import userMessagesEn from '@src/assets/data/locales/en.json'
import messagesEn from '@assets/data/locales/en.json'
import ConfigEn from '@views/category/config/translate/en.json'
import CategoryEn from '@views/category/category/translate/en.json'
import MenuEn from '@src/navigation/translate/en.json'
import AccountEn from '@views/category/system/account/translate/en.json'

const English = {
    ...userMessagesEn,
    ...messagesEn,
    ...ConfigEn,
    ...CategoryEn,
    ...MenuEn,
    ...AccountEn
}
export default English