import userMessagesVn from '@src/assets/data/locales/vi.json'
import messagesVn from '@assets/data/locales/vi.json'
import ConfigVi from '@views/category/config/translate/vi.json'
import CategoryVi from '@views/category/category/translate/vi.json'
import MenuVi from '@src/navigation/translate/vi.json'
import AccountVi from '@views/category/system/account/translate/vi.json'
const Vietnamese = {
    ...userMessagesVn,
    ...messagesVn,
    ...ConfigVi,
    ...CategoryVi,
    ...MenuVi,
    ...AccountVi
    
}
export default Vietnamese