import { createSlice } from '@reduxjs/toolkit'
import themeConfig from '@configs/themeConfig'

// ** Returns Initial Menu Collapsed State
const initialMenuCollapsed = () => {
    const item = window.localStorage.getItem('menuCollapsed')
    //** Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : themeConfig.layout.menu.isCollapsed
}

const initialState = {
    isRTL: themeConfig.layout.isRTL,
    menuCollapsed: initialMenuCollapsed(),
    menuHidden: themeConfig.layout.menu.isHidden,
    contentWidth: themeConfig.layout.contentWidth
};

export const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        handleContentWidth: (state, action) => {
            state.contentWidth = action.payload;
        },
        handleMenuCollapsed: (state, action) => {
            state.menuCollapsed = action.payload;
        },
        handleMenuHidden: (state, action) => {
            state.menuHidden = action.payload;
        },
        handleRTL: (state, action) => {
            state.isRTL = action.payload;
        }
    }
})

// Action creators are generated for each case reducer function
export const { handleContentWidth, handleMenuCollapsed, handleMenuHidden, handleRTL } = layoutSlice.actions

export const selectLayout = (state) => state?.layout

export default layoutSlice.reducer
