// import { Button } from "primereact/button";
import {
    Button
} from 'reactstrap';
import React from "react";

function ErrorFallback(props) {
    const { error, resetErrorBoundary } = props;
    return (
        <div className="p-5">
            <h2>Something went wrong.</h2>
            <Button.Ripple className='mb-3' onClick={() => resetErrorBoundary()}>
                Try again
            </Button.Ripple>
            <details style={{ whiteSpace: 'pre-wrap' }}
            >
                {error.stack}
            </details>
        </div>
    )
}
export default ErrorFallback
