import { createSlice } from '@reduxjs/toolkit'
import themeConfig from '@configs/themeConfig'


const initialState = {
    suggestions: [],
    bookmarks: [],
    query: ''
};

export const navbarSlice = createSlice({
    name: 'navbar',
    initialState,
    reducers: {
        getBookmarks: (state, action) => {
        },
        updateBookmarked: (state, action) => {
        },
        handleSearchQuery: (state, action) => {
            state.query = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { getBookmarks, updateBookmarked, handleSearchQuery } = navbarSlice.actions

export const selectNavbar = (state) => state?.navbar

export default navbarSlice.reducer
