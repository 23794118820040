import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isAuth: false,
    userData: null,
    dataError401: {
        messeger: ""
    },
    avatar: null
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthData: (state, action) => {
            return state = { ...state, userData: action.payload, isAuth: true };
        },
        setAvatarData: (state, action) => {
            state.avatar = action.payload;
        },
        unsetAuthData: (state) => {
            return state = { ...initialState };
        },
        authData401: (state, action) => {
            return state = { ...state, isAuth: false, dataError401: action.payload };
        },
    },
})

// Action creators are generated for each case reducer function
export const { setAuthData, unsetAuthData, authData401, setAvatarData } = authSlice.actions

export const selectUser = (state) => state?.auth?.userData
export const selectAvatar = (state) => state?.auth?.avatar
export const selectAuth = (state) => state?.auth

export default authSlice.reducer


