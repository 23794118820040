import { PAGE_DEFAULT, ROW_DEFAULT } from "constants/global";

export const EMPTY_DATA_SEARCH = {
    "code_config": "",
    type: "",
    "tu_ngay": "",
    "den_ngay": "",
    size: ROW_DEFAULT,
    page: PAGE_DEFAULT,
}
