// ** React Imports
import { lazy, Suspense } from 'react';

// ** Redux Imports
import store from "app/store";
import { Provider } from 'react-redux';

// ** Intl, CASL & ThemeColors Context
import { ToastContainer } from 'react-toastify';
import ability from './configs/acl/ability';
import { AbilityContext } from './utility/context/Can';
import { IntlProviderWrapper } from './utility/context/Internationalization';
import { ThemeContext } from './utility/context/ThemeColors';

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner';

// ** Ripple Button
import './@core/components/ripple-button';

// ** Fake Database
import './@fake-db';

// ** PrismJS
import 'prismjs';
import 'prismjs/components/prism-jsx.min';
import 'prismjs/themes/prism-tomorrow.css';

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css';

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss';

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css';
import './@core/scss/core.scss';
import "./assets/scss/custom/_custom.scss";
import './assets/scss/style.scss';

// ** Service Worker
import { KeycloakProvider } from "@react-keycloak/web";
import ErrorFallback from "components/ErrorBoundary/ErrorFallback";
import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClient, QueryClientProvider, useQueryErrorResetBoundary } from 'react-query';
import { ReactQueryDevtools } from "react-query/devtools";
import keycloak from "./keycloak";

const WrapApp = () => {
    const LazyApp = lazy(() => import("./App"));
    const queryClient = new QueryClient()
    useEffect(() => {
        queryClient.setDefaultOptions({
            queries: {
                staleTime: 5000,
                keepPreviousData: true,
                // cacheTime: 0,
                refetchOnWindowFocus: false,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { reset } = useQueryErrorResetBoundary()
    return (
        <ErrorBoundary
            onReset={reset}
            FallbackComponent={ErrorFallback}
        >
            <Provider store={store}>
                <KeycloakProvider keycloak={keycloak}>
                    <QueryClientProvider client={queryClient}>
                        <Suspense fallback={<Spinner />}>
                            <AbilityContext.Provider value={ability}>
                                <ThemeContext>
                                    <IntlProviderWrapper>
                                        <LazyApp />
                                        <ToastContainer newestOnTop />
                                    </IntlProviderWrapper>
                                </ThemeContext>
                            </AbilityContext.Provider>
                        </Suspense>
                        <ReactQueryDevtools initialIsOpen />
                    </QueryClientProvider>
                </KeycloakProvider>
            </Provider>
        </ErrorBoundary>
    )
}

export default WrapApp
