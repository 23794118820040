import { combineReducers } from "redux";
import authSlice from "app/slice/authSlice";
import featuresReducer from "app/reducers/featuresReducer";
import layoutSlice from "app/slice/layoutSlice";
import navbarSlice from "app/slice/navbarSlice";

export default combineReducers({
    auth:authSlice,
    layout:layoutSlice,
    features:featuresReducer,
    navbar:navbarSlice,
});
