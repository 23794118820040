// ** React Imports
import { useState, createContext } from 'react'

// ** Intl Provider Import
import { IntlProvider } from 'react-intl'


// ** User Language Data
import Vietnamese from '@src/utility/context/translate/vi.js'
import English from '@src/utility/context/translate/en.js'


// ** Menu msg obj
const menuMessages = {
  vi: {...Vietnamese },
  en: { ...English }
}

// ** Create Context
// ** Create Context
const Context = createContext()

const IntlProviderWrapper = ({ children }) => {
  // ** States
  const local = localStorage.getItem("locale") || 'vi'
  let lang
  if (local === 'vi') {
    lang = menuMessages['vi']
  } else {
    lang = menuMessages['en']
  }

  const [locale, setLocale] = useState(local)
  const [messages, setMessages] = useState(lang)

  // ** Switches Language
  const switchLanguage = lang => {
    setLocale(lang)
    setMessages(menuMessages[lang])
    localStorage.setItem("locale", lang)
  }

  return (
    <Context.Provider value={{ locale, switchLanguage }}>
      <IntlProvider key={locale} locale={locale} messages={messages} defaultLocale='vi'>
        {children}
      </IntlProvider>
    </Context.Provider>
  )
}

export { IntlProviderWrapper, Context as IntlContext }
